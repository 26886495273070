// import { faker } from '@faker-js/faker';
// import { sample } from 'lodash';

import { get } from '../utils/request';



const users = [];
const fetchUsers = async () => {
  
try {
  
await get('/api/v2/admins/transactions/get-all/1/10000/createdAt/DESC').
then((res) => {

  console.log("this is giftcards txns", res.data);
  



const fetchUsers = res.data.map((user)=> ({
  id: user.id,
  avatarUrl: `/assets/images/avatars/avatar_${ 1 + 1}.jpg`,
  name: `${user.amount} ${user.currency} Fee: ${user.fee}`,
  company: `${user.quantity} ${user.cardIssuer} ${user.cardType}`,
  isVerified: `${user.userId} ${user.merchantId} ${user.transactionType} ${user.paymentMethod}`,
  status: user.status,
  role: new Date(user.createdAt).toLocaleString(),
  length: user.length,
})); 



users.push(...fetchUsers);

} );

} catch (error) {
  console.error(error); // Handle the error
}
};
fetchUsers();




export default users;
